<template>
  <div class="customers-index width-100">
    <Filters
      :filters="customersFilters"
      filter-key="customers"
      :initial-data="filtersStatus.customers"
    />

    <TableWrapper
      :table-columns="customersColumns"
      table-key="customers"
      :enable-selection="true"
      :bulk-disable="true"
      @bulkDisable="bulkDisableSelectedCustomers"
      @enableCustomer="enableCustomer"
      @disableCustomer="disableCustomer"
      @disableRawData="disableRawData"
      @enableRawData="enableRawData"
      @editCustomer="goToEdit"
      @showUsers="goToShowUsers"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { customersColumns, customersFilters } from '@/config';
import { customersApi } from '@/api';
import lib from '@/lib/functions';
import Filters from '@/components/Filters.vue';
import TableWrapper from '@/components/TableWrapper.vue';

export default {
  name: 'CustomersIndex',
  components: {
    Filters,
    TableWrapper,
  },
  data() {
    return {
      customersColumns,
      customersFilters,
      openCreateCustomerModal: false,
      isModalEdit: false,
      initialCustomerData: {},
      selectedCustomers: [],
    };
  },
  computed: {
    ...mapGetters([
      'filtersStatus',
      'tableFilters',
    ]),
  },
  created() {
    this.parseQueryStringStatus(this.$route.query);
    this.getTableRows();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.params.customerId) {
        vm.getCustomer();
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.parseQueryStringStatus(to.query);
    this.getTableRows();
    next();
  },
  methods: {
    ...mapActions([
      'updateTableRows',
      'updateTableCount',
      'parseQueryStringStatus',
    ]),
    handleDialogClose(status) {
      this.openCreateCustomerModal = false;
      if (status.success) {
        this.getTableRows();
      }
      if (this.isModalEdit) {
        this.isModalEdit = false;
        this.$router.push({ name: 'customersIndex' });
      }
    },
    async getTableRows() {
      try {
        await lib.tableRows('customers', customersApi, this.tableFilters('customers'),
          this.filtersStatus.customers, this.updateTableRows, this.updateTableCount);
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    async enableCustomer(row) {
      try {
        await customersApi.patch(row.id, { isEnabled: true });
        this.getTableRows();
        this.$notify({ showClose: true, type: 'success', message: this.$t('customers.update.success') });
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    async disableCustomer(row) {
      try {
        await customersApi.patch(row.id, { isEnabled: false });
        this.getTableRows();
        this.$notify({ showClose: true, type: 'success', message: this.$t('customers.update.success') });
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    async enableRawData(row) {
      try {
        await customersApi.patch(row.id, { canViewRawData: true });
        this.getTableRows();
        this.$notify({ showClose: true, type: 'success', message: this.$t('customers.update.success') });
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    async disableRawData(row) {
      try {
        await customersApi.patch(row.id, { canViewRawData: false });
        this.getTableRows();
        this.$notify({ showClose: true, type: 'success', message: this.$t('customers.update.success') });
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    async bulkDisableSelectedCustomers(selectedCustomers) {
      try {
        if (selectedCustomers.length) {
          const buildWhereCondition = customers => ({
            id: {
              inq: customers.map(customer => customer.id),
            },
          });
          await customersApi
            .bulkUpdate({ isEnabled: false }, buildWhereCondition(selectedCustomers));
          this.getTableRows();
        }
        this.$message({ showClose: true, type: 'success', message: this.$t('customers.update.success') });
      } catch (error) {
        throw error;
      }
    },
    async getCustomer() {
      try {
        const customer = await customersApi.show(this.$route.params.customerId);
        this.initialCustomerData = {
          name: customer.data.name,
          address: customer.data.address,
          phoneNumber: customer.data.phoneNumber,
          mobile: customer.data.mobile,
          vat: customer.data.vat,
          internalReference: customer.data.internalReference,
          // sdi: customer.data.sdi,
          city: customer.data.city,
          province: customer.data.province,
          email: customer.data.email,
          fiscalCode: customer.data.fiscalCode,
          isEnabled: customer.data.isEnabled,
          agent: customer.data.agent,
          canViewRawData: customer.data.canViewRawData,
        };
        this.isModalEdit = true;
        this.openCreateCustomerModal = true;
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
        this.$router.push({
          name: 'customersIndex',
        });
      }
    },
    goToEdit(row) {
      this.$router.push({
        name: 'customersEdit',
        params: { customerId: row.id },
      });
    },
    goToShowUsers(row) {
      const filterData = {
        customerName: [{
          key: row.name,
          label: row.name,
        }],
      };
      this.$router.push({
        name: 'usersIndex',
        query: {
          filterKey: 'users',
          filterData: JSON.stringify(filterData),
        },
      });
    },
  },
};
</script>
<style lang="scss">
.disable-selected {
  margin-top: 1rem;
}
</style>
